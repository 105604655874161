import { makeStyles } from 'lib/makeStyles';
import React, { ComponentProps, memo } from 'react';

import {
  TypographyColorProps,
  TypographyMakeStyleProps,
  TypographyProps,
  TypographyStyleProps,
  useTypographyAlignStyles,
  useTypographyColorStyles,
  useTypographyWhitespaceStyles,
  useWrapperStyles
} from './index';

export type SmallTextProps = ComponentProps<'p'> & TypographyProps & TypographyStyleProps;

const useStyles = makeStyles<SmallTextProps & TypographyMakeStyleProps>()({
  root: (props) => [
    'text-smallMobile md:text-small',
    {
      'font-medium': !props.bold && !props.thin,
      'font-normal': props.thin,
      'font-semibold': props.bold,
      italic: props.italic
    },
    !props.disableColorStyle && props.colorClassName,
    props.fontClassName,
    props.alignClassName,
    props.whitespaceClassName
  ]
});

const SmallText: React.FC<SmallTextProps> = memo(
  ({
    as = 'p',
    bold = false,
    italic = false,
    dark = false,
    enableHover = false,
    active = false,
    disabled = false,
    color = 'secondary',
    disableColorStyle = false,
    className,
    children,
    ...restProps
  }: SmallTextProps) => {
    const props = {
      as,
      bold,
      italic,
      dark,
      enableHover,
      active,
      disabled,
      color,
      disableColorStyle,
      children,
      className,
      ...restProps
    };

    const wrapperStyles = useWrapperStyles({ className });
    const colorStyles = useTypographyColorStyles(props as TypographyColorProps);
    const alignStyles = useTypographyAlignStyles(props);
    const whitespaceStyles = useTypographyWhitespaceStyles(props);

    const styles = useStyles({
      colorClassName: colorStyles.color,
      alignClassName: alignStyles.align,
      whitespaceClassName: whitespaceStyles.whitespace,
      ...props
    });

    const Element = () => React.createElement(as, { className: styles.root }, children);

    return className ? (
      <div className={wrapperStyles.wrapper}>
        <Element />
      </div>
    ) : (
      <Element />
    );
  }
);

SmallText.displayName = 'SmallText';

export { SmallText };
