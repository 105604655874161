import { Panel, PanelProps } from 'components/layout/Panel/Panel';
import { PanelFlex, PanelFlexProps } from 'components/layout/Panel/PanelFlex';
import React from 'react';

export type PanelItemProps = PanelFlexProps & PanelProps;

export const PanelItem: React.FC<PanelItemProps> = React.memo((props: PanelItemProps) => {
  const {
    active = false,
    as,
    children,
    className,
    enableBackground = false,
    enableBorder = false,
    enableHover = false,
    fullHeight,
    fullWidth,
    disabled = false,
    hasSelectedSticky = false,
    intent = 'neutral',
    relative = false,
    rounded = false,
    shadow = false,
    hasTransparentBorder,
    ...panelFlexOwnProps
  } = props;
  return (
    <Panel
      active={active}
      as={as}
      className={className}
      disabled={disabled}
      enableBackground={enableBackground}
      enableBorder={enableBorder}
      enableHover={enableHover}
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      hasSelectedSticky={hasSelectedSticky}
      hasTransparentBorder={hasTransparentBorder}
      intent={intent}
      relative={relative}
      rounded={rounded}
      shadow={shadow}>
      <PanelFlex fullWidth fullHeight {...panelFlexOwnProps}>
        {children}
      </PanelFlex>
    </Panel>
  );
});

PanelItem.displayName = 'PanelItem';
