import { makeStyles } from 'lib/makeStyles';
import React, { ComponentProps, memo } from 'react';

import {
  TypographyColorProps,
  TypographyMakeStyleProps,
  TypographyProps,
  TypographyStyleProps,
  useTypographyAlignStyles,
  useTypographyColorStyles,
  useTypographyWhitespaceStyles,
  useWrapperStyles
} from './index';

export type MetaTextProps = ComponentProps<'span'> &
  TypographyProps &
  TypographyStyleProps & {
    elementClassName?: string;
  };

const useStyles = makeStyles<MetaTextProps & TypographyMakeStyleProps>()({
  root: (props) => [
    'text-meta',
    {
      'font-medium': !props.bold && !props.thin,
      'font-semibold': props.bold,
      'font-normal': props.thin,
      italic: props.italic
    },
    {
      'hover:cursor-pointer': props.as === 'label'
    },
    !props.disableColorStyle && props.colorClassName,
    props.fontClassName,
    props.alignClassName,
    props.elementClassName,
    props.whitespaceClassName
  ]
});

export const MetaText: React.FC<MetaTextProps> = memo(
  ({
    as = 'span',
    thin = false,
    bold = false,
    italic = false,
    dark = false,
    enableHover = false,
    active = false,
    disabled = false,
    color = 'primary',
    disableColorStyle = false,
    children,
    className,
    elementClassName,
    ...restProps
  }: MetaTextProps) => {
    const props = {
      as,
      thin,
      bold,
      italic,
      dark,
      enableHover,
      active,
      disabled,
      color,
      disableColorStyle,
      children,
      className,
      elementClassName,
      ...restProps
    };
    const wrapperStyles = useWrapperStyles({ className: props.className });
    const colorStyles = useTypographyColorStyles(props as TypographyColorProps);
    const alignStyles = useTypographyAlignStyles(props);
    const whitespaceStyles = useTypographyWhitespaceStyles(props);

    const styles = useStyles({
      colorClassName: colorStyles.color,
      alignClassName: alignStyles.align,
      whitespaceClassName: whitespaceStyles.whitespace,
      elementClassName: props.elementClassName,
      ...props
    });

    const Element = () => React.createElement(props.as, { className: styles.root }, props.children);

    return props.className ? (
      <div className={wrapperStyles.wrapper}>
        <Element />
      </div>
    ) : (
      <Element />
    );
  }
);

MetaText.displayName = 'MetaText';
