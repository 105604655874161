'use client';
import { makeStyles } from 'lib/makeStyles';
import dynamic from 'next/dynamic';
import React, { createElement, useCallback, useEffect } from 'react';
import { HeroIconName, HeroIconNameV1 } from 'types/heroIcons';

type OwnProps = {
  /**
   * See more in https://tailwindcss.com/docs/background-position
   */
  position?:
    | 'bottom'
    | 'center'
    | 'left'
    | 'left-bottom'
    | 'left-top'
    | 'right'
    | 'right-bottom'
    | 'right-top'
    | 'top';
  ariaHidden?: boolean;
  title?: string;
  onLoad?: () => void;
} & ParentClassNameProp;

export type HeroIconType = 'solid' | 'outline' | 'solid-mini';
export type HeroIconTypeV1 = 'solid' | 'outline';

export type HeroIconProps =
  | ({
      version?: '2';
      name: HeroIconName;
      type: HeroIconType;
    } & OwnProps)
  | ({
      version: '1';
      name: HeroIconNameV1;
      type: HeroIconTypeV1;
    } & OwnProps);

type CSSProps = Pick<HeroIconProps, 'position'> & ParentClassNameProp;

const useStyles = makeStyles({
  svgMask: (props: CSSProps) => [`bg-no-repeat bg-${props.position}`, props.className]
});

const iconFolder = new Map<HeroIconType, string>([
  ['solid-mini', '20/solid/'],
  ['solid', '24/solid/'],
  ['outline', '24/outline/']
]);

const iconFolderV1 = new Map<HeroIconTypeV1, string>([
  ['solid', 'solid/'],
  ['outline', 'outline/']
]);

const HeroIcon: React.FC<HeroIconProps> = React.memo(
  ({
    name,
    className,
    position = 'center',
    ariaHidden = true,
    title,
    type,
    version = '2',
    onLoad
  }) => {
    const styles = useStyles({ className, position });
    const iconModuleResolver = useCallback(
      () =>
        // we cannot extract this to a module or path string cause webpack fails
        version === '2'
          ? import('heroicons2/' + iconFolder.get(type) + name + '.svg')
          : import('heroicons1/' + iconFolderV1.get(type as HeroIconTypeV1) + name + '.svg'),
      [version, name, type]
    );

    return createElement(
      dynamic(() =>
        iconModuleResolver()
          .catch(() => new Error(`HeroIcon${version} ${name} is not found`))
          .then(({ default: svgProps }) =>
            // eslint-disable-next-line react/display-name
            () => {
              useEffect(() => {
                onLoad && onLoad();
              }, []);

              return (
                <div
                  role="img"
                  aria-label={title}
                  aria-hidden={ariaHidden}
                  className={styles.svgMask}
                  style={{
                    // svgProps in storybook is just a string
                    mask: `url("${svgProps.src ?? svgProps}")`,
                    WebkitMask: `url("${svgProps.src ?? svgProps}")`
                  }}
                />
              );
            }
          )
      ),
      {}
    );
  }
);

HeroIcon.displayName = 'HeroIcon';

export { HeroIcon };
