import { makeStyles } from 'lib/makeStyles';
import React, { ComponentProps, memo } from 'react';

import {
  TypographyColorProps,
  TypographyMakeStyleProps,
  TypographyProps,
  TypographyStyleProps,
  useTypographyAlignStyles,
  useTypographyColorStyles,
  useTypographyWhitespaceStyles,
  useWrapperStyles
} from './';

export type BodyTextProps = ComponentProps<'p'> & TypographyProps & TypographyStyleProps;

const useStyles = makeStyles<BodyTextProps & TypographyMakeStyleProps>()({
  root: (props) => [
    {
      'text-body': !props.large,
      'text-bodyLarge': props.large,
      'font-normal': !props.bold && !props.medium,
      'font-semibold': props.bold,
      'font-medium': props.medium,
      italic: props.italic
    },
    !props.disableColorStyle && props.colorClassName,
    props.fontClassName,
    props.alignClassName,
    props.whitespaceClassName
  ]
});

const BodyText: React.FC<BodyTextProps> = memo(
  ({
    as = 'p',
    children,
    className,
    medium = false,
    bold = false,
    large = false,
    italic = false,
    dark = false,
    enableHover = false,
    active = false,
    disabled = false,
    color = 'secondary',
    disableColorStyle = false,
    ...restProps
  }: BodyTextProps) => {
    const props = {
      as,
      children,
      className,
      medium,
      bold,
      large,
      italic,
      dark,
      enableHover,
      active,
      disabled,
      color,
      disableColorStyle,
      ...restProps
    };

    const wrapperStyles = useWrapperStyles({ className });
    const colorStyles = useTypographyColorStyles(props as TypographyColorProps);
    const alignStyles = useTypographyAlignStyles(props);
    const whitespaceStyles = useTypographyWhitespaceStyles(props);

    const styles = useStyles({
      colorClassName: colorStyles.color,
      alignClassName: alignStyles.align,
      whitespaceClassName: whitespaceStyles.whitespace,
      ...props
    });

    const Element = () => React.createElement(props.as, { className: styles.root }, children);

    return className ? (
      <div className={wrapperStyles.wrapper}>
        <Element />
      </div>
    ) : (
      <Element />
    );
  }
);

BodyText.displayName = 'BodyText';

export { BodyText };
