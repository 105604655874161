import { useTranslation } from 'hooks/useContentful/useTranslation';
import { BackArrowIcon } from 'icons/monochrome/BackArrowIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

import { GlyphProps } from 'components/common/Glyph/Glyph';
import { CircleButtonOwnProps, RoundButton } from './RoundButton';

export type BackButtonProps = ClickEventProp &
  Pick<CircleButtonOwnProps, 'circle'> &
  ParentClassNameProp & { iconSize?: Pick<GlyphProps, 'size'>['size'] } & ChildrenProp;

const useStyles = makeStyles<Omit<BackButtonProps, 'iconSize'>>()({
  root: (props) => ['cursor-pointer', props.className]
});

//  TODO combine with BackButtonLink
const BackButton: React.FC<BackButtonProps> = memo(({ iconSize = 'md', children, ...props }) => {
  const { t } = useTranslation();
  const styles = useStyles(props);

  return (
    <RoundButton {...props} className={styles.root} as="a" title={t('BACK')} aria-label={t('BACK')}>
      <BackArrowIcon size={iconSize} />
      {children}
    </RoundButton>
  );
});

BackButton.displayName = 'BackButtonLink';

export { BackButton };
