import { useCustomKeyDown } from 'hooks/useKeyboard';
import { CloseIcon } from 'icons/monochrome/CloseIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

export type CloseButtonProps = {
  onClose: GenericHandler;
  hasPosition?: boolean;
  defaultPadding?: boolean;
  hasTabClose?: boolean;
} & ParentClassNameProp;

const useStyles = makeStyles<Omit<CloseButtonProps, 'onClose'>>()({
  close: (props) => [
    'pointer-cursor',
    {
      'ml-2': props.defaultPadding,
      'absolute top-4 right-4': props.hasPosition
    },
    props.className
  ],
  icon: ['text-primaryIcon hover:text-primaryIconHover']
});

export const CloseButton: React.FC<CloseButtonProps> = memo((props: CloseButtonProps) => {
  const { onClose, hasTabClose, hasPosition = false, defaultPadding = true } = props;
  const styles = useStyles({
    hasPosition,
    defaultPadding,
    ...props
  });

  const onClickCloseIcon = async ({ e }: GenericEvent) => {
    e?.preventDefault();
    return onClose(e);
  };

  const handleCustomKeyDown = useCustomKeyDown({
    onEnter: onClickCloseIcon,
    onSpace: onClickCloseIcon,
    onTab: (e) => (hasTabClose ? onClickCloseIcon(e) : undefined)
  });
  return (
    <div
      role="button"
      title={'Close'}
      aria-label={'Close'}
      tabIndex={0}
      className={styles.close}
      onClick={(e) => onClickCloseIcon({ e })}
      onKeyDown={handleCustomKeyDown}>
      <CloseIcon className={styles.icon} />
    </div>
  );
});

CloseButton.displayName = 'CloseButton';
